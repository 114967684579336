import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import BreadcrumbNav from '../BreadcrumbNav';
import Header from '../Header';
import './main.css';
import icon from '../../../assets/favicon.ico';
import constants from '../../../core/constants';
import { MainProps } from './types';

const { APP_NAME } = constants;

const Main = (props: MainProps) => {
    const {
        children,
        title,
        breadcrumb,
        className,
        backLink,
        showHeader,
        beforeHeader,
        afterHeader,
        floatingHeader,
        headerTitle,
        menuPlacement,
        menuWidth,
        contentId,
    } = props;

    return (
        <div className={`page-wrap ${className}`}>
            <Helmet
                title={typeof title === 'object' ? title.props.children : title}
                titleTemplate={`%s - ${APP_NAME}`}
                defaultTitle={`${APP_NAME}`}
                meta={[
                    { name: 'description', content: `${APP_NAME} website` },
                    { property: 'og:type', content: 'website' },
                ]}>
                <link rel="shortcut icon" href={icon} />
            </Helmet>
            {showHeader && (
                <Header
                    afterHeader={afterHeader}
                    beforeHeader={beforeHeader}
                    floating={floatingHeader}
                    title={headerTitle || title}
                    backLink={backLink}
                    menuPlacement={menuPlacement}
                    menuWidth={menuWidth} />
            )}
            <Layout.Content className="main-content" id={contentId}>
                {breadcrumb ? <BreadcrumbNav navigation={breadcrumb} /> : null}
                {children}
            </Layout.Content>
        </div>
    );
};

Main.defaultProps = {
    afterFooter: null,
    afterHeader: null,
    beforeFooter: null,
    beforeHeader: null,
    logo: '',
    showFooter: true,
    showHeader: true,
    breadcrumb: [],
    floatingFooter: false,
    contentId: 'main-content',
};

export default Main;
