/* eslint-disable */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';
import { persistor } from "../store";
import { logoutUser } from "../user/userApi";
import NotFound from '../../pages/NotFound';
import constants from '../constants';
import {AppDispatch, ProtectedRouteProps} from '../types';

interface RouteItem {
    path: string;
    component: React.ComponentType<any>;
    type?: string;
    role?: string;
    allowedOnlyRoles?: string[];
}

interface CoreRouterProps {
    routes: RouteItem[];
}

interface LogoutRouteProps {
    path: string;
    exact: boolean;
    persistor: any; // Replace 'any' with the correct type for your persistor
}

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES_TIME,
} = constants;

const onlyAuthorisedAllowed = ():boolean => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate:string = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const parsedAuthDate = new Date(authDate);
            const now = new Date();
            const differenceMilliseconds = now.getTime() - parsedAuthDate.getTime();
            const differenceMinutes = Math.floor(differenceMilliseconds / 1000 / 60);
            if (differenceMinutes >= LOCALSTORAGE_EXPIRES_TIME) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

const ProtectedRoute = ({ children }:ProtectedRouteProps) => {
    if (onlyAuthorisedAllowed()) {
        return <>{children}</>;
    }
    const dispatch = useDispatch<AppDispatch>();
    dispatch(logoutUser());
    return <Navigate to="/" />;
}

const cleanUpLocalStorage = () => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();
    window.location.href = '/';
    return null;
};

const LogoutRoute:React.FC<LogoutRouteProps> = ({ exact, path, persistor }) => {
    return cleanUpLocalStorage();
}

const CoreRouter: React.FC<CoreRouterProps> = ({ routes }) => (
    <Routes>
        {routes.map((route, index) => {
                let element = <route.component/>;
                if (route.type === 'auth') {
                    console.log('Auth route');
                    element = <ProtectedRoute>
                        <route.component/>
                    </ProtectedRoute>;
                }
                return <Route
                    key={index}
                    // exact
                    path={route.path}
                    element={element} />;
            }
        )}
        <Route element={<NotFound />} />
    </Routes>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    LogoutRoute,
};