/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUsers, createCareHome, createUser, deleteUser, updateUser } from './accountApi';
import { DataProps, ZoneData, CareHome } from './types';

interface AccountState {
    users: DataProps;
    zoneData: ZoneData[];
    careHomes: CareHome[];
    isFetching: boolean;
    isExporting: boolean;
    error: string | null;
}

const initialState: AccountState = {
    users: [],
    zoneData: [],
    careHomes: [],
    isFetching: false,
    isExporting: false,
    error: null,
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setExporting: (state, action) => {
            state.isExporting = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isFetching = false;
                state.users = action.payload;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch users';
            })
            .addCase(createCareHome.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(createCareHome.fulfilled, (state, action) => {
                state.isFetching = false;
                if (Array.isArray(state.careHomes)) {
                    state.careHomes.push(action.payload.care_home);
                } else {
                    state.careHomes = [action.payload.care_home];
                }
            })
            .addCase(createCareHome.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to create care home';
            })
            .addCase(createUser.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(createUser.fulfilled, (state) => {
                state.isFetching = false;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to create user';
            })
            .addCase(deleteUser.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isFetching = false;
                state.users = state.users.filter((user) => user.id !== action.payload);
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to delete user';
            })
            .addCase(updateUser.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isFetching = false;
                state.users = state.users.map((user) => (user.id === action.payload.id ? action.payload : user));
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to update user';
            });
    },
});

export const { setExporting } = accountSlice.actions;
export default accountSlice.reducer;
