/* eslint-disable react/require-default-props */
import React from 'react';
import { Table, Tooltip } from 'antd';
import { ExclamationIcon } from 'components/Elements/CustomSVGIcon';
import './tableComponent.scss';

const TableComponent: React.FC<TableComponentProps> = ({ data, isExporting }) => {
    const renderRowClassName = (record: unknown, index: number) => (index % 2 === 0 ? 'even-row' : 'odd-row');

    const columns = [
        {
            title: 'Care Home',
            dataIndex: 'title',
            key: 'careHome',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Completed',
            dataIndex: 'completed',
            key: 'completed',
        },
        {
            title: 'Reassessments',
            dataIndex: 'reassessments',
            key: 'reassessments',
        },
        {
            title: (
                <span>
                    Abandoned{' '}
                    <Tooltip
                        title="An Abandoned ISTUMBLE process is when a user has not completed the email confirmation form in the mobile app."
                        overlayInnerStyle={{ backgroundColor: '#00AEEF', color: 'white' }}
                        mouseEnterDelay={0.1}
                        mouseLeaveDelay={0.1}
                        trigger="hover">
                        <span style={{ position: 'relative', top: '5px' }}>
                            <ExclamationIcon style={{ marginLeft: 4 }} />
                        </span>
                    </Tooltip>
                </span>
            ),
            dataIndex: 'abandoned',
            key: 'abandoned',
        },
    ];
    const pagination = isExporting ? false : { pageSize: 10 };
    return data ? (
        <Table
            dataSource={data.map((item, index) => ({
                ...item,
                key: index.toString(),
            }))}
            columns={columns}
            pagination={pagination}
            rowClassName={renderRowClassName} />
    ) : null;
};

export default TableComponent;
